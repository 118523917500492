import React from "react";
import { Link } from "react-router-dom";

import background from "../images/therapies.jpg";
import spa_day_pic from "../images/spa_days.png";
import phone2 from "../images/phone2.png";
import brochure from "../pdfs/balcony_brochure.pdf";
import spa_day_poster from "../pdfs/spa_day_poster_dec_23.pdf";

const BeautyPage = (props) => {
	const loc = props.match.params.loc;
	return (
		<div id='beautypage'>
			<div className='row justify-content-center'>
				<div className='col-lg-8'>
					<img className='img-fluid mb-4' src={background} alt='header' />
					<h4>Health & Beauty</h4>
					<p>
						Health & Beauty can also be found at The Fountain Spa. With a team
						of highly Professional Therapists, a complete treatment guide is
						available to both Members and Non-Members alike.
					</p>
					<p>
						Treatments available extend through Waxing to Massage and from
						Tanning to Complementary Therapies.
					</p>
					<p>
						Our fully qualified, experienced Therapists offer an extensive range
						of treatments to suit all your needs.
					</p>
				</div>
			</div>
			<hr />
			<div className='row'>
				<div className='col-xs-12 col-md-4'>
					<b>Our prices start at:</b>
					<ul>
						<li>Mini Manicure - £22.50</li>
						<li>Manicure - £30.00</li>
						<li>Body Treatment - £48.00</li>
						<li>Pamper Days - £60.00</li>
						<li>Energise Facial - £40.00</li>
						<li>Facials - £52.00</li>
						<li>Massage - £38.00</li>
						<li>Tanning - £20.00</li>
						<li>Waxing - £11.00</li>
					</ul>
					<p>A full range of Elemis retail products are also available.</p>
					<p>
						Download our{" "}
						<a href={brochure} target='_blank' rel='noreferrer noopener'>
							Balcony brochure
						</a>
					</p>
				</div>
				<div className='col-xs-12 col-md-8'>
					<div className='row justify-content-around align-items-end'>
						<div className='col-xs-12 col-s-6 text-center'>
							<div className='row'>
								<div className='col-12'>
									<img
										src={spa_day_pic}
										width='200px'
										alt='Stacked stones'
										className='ml-2'
									/>
								</div>
							</div>
							<div className='row'>
								<div className='col-12'>
									<a
										href={spa_day_poster}
										target='_blank'
										rel='noreferrer noopener'
										className='btn btn-alt bg-dark mb-3'
										style={{ width: "200px" }}
									>
										Spa Days
									</a>
								</div>
							</div>
						</div>
						<div className='col-xs-12 col-s-6'>
							<b>Did you know we also offer the following services?</b>
							<ul style={{ marginLeft: "22px" }}>
								<li>Sports and Remedial Massage</li>
								<li>Chiropractic Clinic</li>
								<li>Indian Head Massage</li>
								<li>Swedish Massage</li>
							</ul>
							<div id='other-services' className='text-center'>
								<Link
									to={`/${loc}/therapies`}
									className='btn btn-alt bg-dark mb-3'
								>
									Click here to find out more
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='row align-items-center'>
				<img src={phone2} alt='phone' height='48px' className='pr-2' /><p style={{marginBottom: '0px'}}>For all bookings and further enquiries please call: <b>0131 468 8001</b></p>
			</div>
		</div>
	);
};

export default BeautyPage;
