import React from "react";

import background from "../images/contact.png";
import map from "../pdfs/edinburgh_map.pdf";

const ContactPage = (props) => {
  const loc = props.match.params.loc;
  return (
    <div id="contactpage">
      <div className="row justify-content-center">
        <div className="col-lg-8 text-center">
          <img className="img-fluid mb-4" src={background} alt="header" />
          <h4>Contact</h4>
          {loc === "glenrothes" && (
            <p>
              Fountain Spa leisure club,
              <br />
              Balgeddie House Hotel,
              <br />
              Balgeddie Way, Glenrothes, Fife, Scotland, KY6 3QA
              <br />
              Tel:{" "}
              <u>
                <b style={{ color: "#1a82b8" }}>+44 (0) 1592 742727</b>
              </u>
              <br />
              Fax: +44 (0) 1592 621702
              <br />
              E-mail:{" "}
              <a href="mailto:spa@balgeddiehouse.com">
                <u>
                  <b style={{ color: "#1a82b8" }}>spa@balgeddiehouse.com</b>
                </u>
              </a>
            </p>
          )}
          {loc === "edinburgh" && (
            <p>
              The Fountain Spa leisure club,
              <br />
              Kings Manor Hotel,
              <br />
              100 Milton Road East, Edinburgh, Scotland EH15 2NP
              <br />
              Tel:{" "}
              <u>
                <b style={{ color: "#1a82b8" }}>+44 (0) 131 669 0444</b>
              </u>
              <br />
              Direct: +44 (0)131 468 8001
              <br />
              Fax: +44 (0) 131 669 6650
              <br />
              E-mail:{" "}
              <a href="mailto:spa.manager@kingsmanor.com">
                <u>
                  <b style={{ color: "#1a82b8" }}>spa.manager@kingsmanor.com</b>
                </u>
              </a>
            </p>
          )}
        </div>
      </div>
      <hr />
      <div className="row">
        <h5>Directions</h5>
        {loc === "edinburgh" && (
          <a href={map} target="_blank" rel="noreferrer noopener">
            (Download Map)
          </a>
        )}
      </div>
      {loc === "glenrothes" && (
        <div>
          <div className="row">
            <div className="col-12 col-md-6">
              <b>Grid Coordinates:</b>
              <p>
                OS Grid reference NO 25948 02761 <br />
                Eastings 325948 Northings 702761
                <br />
                Lat. 56.211645 Long.-3.1954658
              </p>
            </div>
            <div className="col-12 col-md-6">
              <b>Getting Here By Car:</b>
              <p>
                If using Sat Nav we do not recommend using the postcode as it is
                not accurate. We recommend using the street address, Balgeddie
                Way, Glenrothes.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-6">
              <b>From The North:</b>
              <p>
                Travel south heading for the Queensferry Crossing. When on the
                A92 (the main road between Dundee and M90) exit by turning right
                at the first sign for Glenrothes North and Leslie. Straight
                through first roundabout. Turn right at the next roundabout
                (Brown Tourist Sign: Balgeddie House Hotel). Continue on this
                road over one mini roundabout and at the second mini roundabout
                turn left into Balgeddie Way.
              </p>
            </div>
            <div className="col-12 col-md-6">
              <b>From The South:</b>
              <p>
                From Edinburgh Airport, travel north on M90 over the Queensferry
                Crossing. Leave M90 at exit 2a, signed Glenrothes. Continue on
                A92 until, signed for Glenrothes North and Leslie. Turn left
                onto A911, straight through three roundabouts. At next
                roundabout turn right onto B969 (Brown Tourist Board Sign:
                Balgeddie House Hotel). At the next roundabout turn left (Brown
                Tourist Board Sign: Balgeddie House Hotel).Continue on this road
                over one mini roundabout and at the second mini roundabout turn
                left into Balgeddie Way.
              </p>
            </div>
          </div>
        </div>
      )}
      {loc === "edinburgh" && (
        <div>
          <div className="row">
            <div className="col-12 col-md-6">
              <b>Arriving By Car:</b>
              <p>
                If you’re approaching Edinburgh from the west, follow the A720
                city bypass all the way to the A1. At that point, turn left to
                join visitors coming in from the east on the A1. Continue on the
                A1 for a mile and a half. Cross the Asda roundabout. At the
                first set of lights, turn right on to the A199. The hotel is on
                the right-hand side of the road after about 330 metres. There’s
                plenty of parking space.
              </p>
            </div>
            <div className="col-12 col-md-6">
              <b>Arriving By Rail:</b>
              <p>
                From Edinburgh Waverly Station, take the crossrail service to
                Brunstane. The trip takes just 7 minutes. Leave the station on
                the Brunstane Road South side. Turn left, and walk to the end of
                the road (about 300 metres), then turn right on to Milton Road
                East. The hotel is about 300 metres on your right.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <b>Arriving By Bus:</b>
              <p>
                In Princes Street, look for the stops for Lothian Buses service
                44. You’ll find them on the shops side of the street at the
                junctions with Castle Street and Frederick Street, and opposite
                the Scott Monument. Buses run roughly every 15 minutes, and the
                journey takes about 20 minutes. Just ask the driver to let you
                know when to get off. Or you could take the First Bus Service
                numbers 44 or 124 from St Andrew’s Square. Please check the{" "}
                <a
                  href="https://www.lothianbuses.com/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Lothian Buses website
                </a>{" "}
                for service updates.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <b>GPS Coordinates:</b>
              <p>GPS 55 56 40.27 N , 3 05 44.27W</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactPage;
