import React from "react";
import { Link } from "react-router-dom";
import fountainSpaLogo from "../images/fountain_spa_logo.jpg";
import menuIcon from "../images/navigation-menu.png";

import eWelcomePack from "../pdfs/edinburgh_welcome_pack.pdf";
import gWelcomePack from "../pdfs/glenrothes_welcome_pack.pdf";
import eTimetable from "../pdfs/edinburgh_timetable.pdf";
import gTimetable from "../pdfs/glenrothes_timetable.pdf";

const NavBar = (props) => {
  const location = props.location.pathname;

  return (
    <>
      <div className="row">
        <div className="col-6">
          <img
            className="img-fluid"
            src={fountainSpaLogo}
            alt="fountain spa logo"
          />
        </div>
      </div>
      <nav className="navbar-collapsed">
        <Link to="" className="nav-link">
          HOME
        </Link>
        <Link to="/menu">
          <img src={menuIcon} height="35px" alt="menu icon" />
        </Link>
      </nav>
      <nav className="navbar navbar-expand navbar-dark bg-dark justify-content-between">
        <ul className="navbar-nav">
          <li className={location === "/" ? "active nav-item" : "nav-item"}>
            <Link to="" className="nav-link">
              Home
            </Link>
          </li>
          <li className="nav-item noselect">
            <span className="nav-link dropdown-toggle">Glenrothes Spa</span>
            <ul className="dropdown-content">
              <Link to="/glenrothes/membership" replace>
                <li>Membership</li>
              </Link>
              <Link to="/glenrothes/fitness" replace>
                <li>Fitness</li>
              </Link>
              <Link to="/glenrothes/wetside" replace>
                <li>Wetside Facilities</li>
              </Link>
              <Link to="/glenrothes/classes" replace>
                <li>Classes</li>
              </Link>
              <Link to="/glenrothes/personal_training">
                <li>Personal Training</li>
              </Link>
              <a href={gTimetable} target="_blank" rel="noreferrer noopener">
                <li>Class Timetable</li>
              </a>
              <a href={gWelcomePack} target="_blank" rel="noreferrer noopener">
                <li>Information Pack</li>
              </a>
              <Link to="/glenrothes/faq" replace>
                <li>FAQ</li>
              </Link>
              <Link to="/glenrothes/contact" replace>
                <li>Contact</li>
              </Link>
            </ul>
          </li>
          {/* <li className="nav-item noselect">
            <span className="nav-link dropdown-toggle">Edinburgh Spa</span>
            <ul className="dropdown-content">
              <Link to="/edinburgh/membership" replace>
                <li>Membership</li>
              </Link>
              <Link to="/edinburgh/fitness" replace>
                <li>Fitness</li>
              </Link>
              <Link to="/edinburgh/wetside" replace>
                <li>Wetside Facilities</li>
              </Link>
              <Link to="/edinburgh/classes" replace>
                <li>Classes</li>
              </Link>
              <a href={eTimetable} target="_blank" rel="noreferrer noopener">
                <li>Class Timetable</li>
              </a>
              <Link to="/edinburgh/health_and_beauty">
                <li>Health & Beauty</li>
              </Link>
              <Link to="/edinburgh/therapies" replace>
                <li>Complementary Therapies</li>
              </Link>
              <Link to="/edinburgh/personal_training">
                <li>Personal Training</li>
              </Link>
              <a href={eWelcomePack} target="_blank" rel="noreferrer noopener">
                <li>Information Pack</li>
              </a>
              <Link to="/edinburgh/faq" replace>
                <li>FAQ</li>
              </Link>
              <Link to="/edinburgh/contact" replace>
                <li>Contact</li>
              </Link>
            </ul>
          </li> */}
          <li className="nav-item noselect">
            <span className="nav-link dropdown-toggle">Timetables</span>
            <ul className="dropdown-content">
              <a
                href={require("../pdfs/glenrothes_timetable.pdf")}
                target="_blank"
                rel="noreferrer noopener"
              >
                <li>Balgeddie Classes</li>
              </a>
              {/* <a
                href={require("../pdfs/edinburgh_timetable.pdf")}
                target="_blank"
                rel="noreferrer noopener"
              >
                <li>Kings Manor Classes</li>
              </a> */}
            </ul>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default NavBar;
