import React from "react";

import wetsideLogo from "../images/wetside.jpg";

class WetsidePage extends React.Component {
	render() {
		const loc = this.props.match.params.loc;
		return (
			<div id='wetsidepage'>
				<div className='row justify-content-center'>
					{loc === "edinburgh" && (
						<div className='col-lg-8'>
							<img className='img-fluid mb-4' src={wetsideLogo} alt='header' />
							<h4>Wetside Facilities</h4>
							<p>
								The Fountain Spa leisure club boasts a fantastic range of
								Wet-side facilities that include a 20 metre Swimming Pool, Spa
								Bath, Toddlers’ Pool, Sauna and Steam Room. Complemented with
								various relaxation areas, this part of The Fountain Spa provides
								the ideal escape from everyday hustle and bustle. With mood
								lighting to reflect the Poolside atmosphere, be it Lane Swimming
								or Aquafit, Recreation or Relaxation our Wet-side facilities are
								the ideal place to feel better about yourself.
							</p>
							<p>
								At a constant depth of 1.2m this area is ideal for relaxation
								and recreation and gives a great platform for Aqua Aerobic
								classes.
							</p>
						</div>
					)}
					{loc === "glenrothes" && (
						<div className='col-lg-8'>
							<img className='img-fluid mb-4' src={wetsideLogo} alt='header' />
							<h4>Wetside Facilities</h4>
							<p>
								The Fountain Spa leisure club boasts a fantastic range of Wet
								side facilities that include a 17 metre Swimming Pool, Sauna and
								Steam Room. This warm and bright area of the leisure club
								provides the ideal escape for the outdoor elements and every day
								hustle and bustle. At a constant depth of 1.2m this area is
								ideal for relaxation and recreation. Or maybe group exercise is
								your preference and then our Aquafit classes will meet your
								needs. Our Sauna and Steam room are both spacious and provide
								the ideal retreat for wet or dry heat.
							</p>
						</div>
					)}
				</div>
				<hr />
				<div className='row'>
					<div className='col-lg-4'>
						<b>Child Access Times (under 15's):</b>
						<ul>
							<li>Mon - Thur: 9:00am - 7:00pm</li>
							<li>Fri: 8:00am - 8:00pm</li>
							<li>Sat & Sun: All Day</li>
						</ul>
					</div>
					<div className='col-lg-4'>
						<b>Poolside Rules:</b>
						<ul>
							<li>Children under 15yrs must be supervised by an adult</li>
							<li>Maximum supervision of 1 adult per 2 children under 15yrs</li>
						</ul>
					</div>
				</div>
			</div>
		);
	}
}

export default WetsidePage;
