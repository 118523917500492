import React from "react";

import heart from "../images/footer_healthy_heart.jpg";
import nutrition from "../images/footer_nutrition.jpg";
import digestive from "../images/footer_digestive.jpg";
import core from "../images/footer_core.jpg";
import lung from "../images/footer_lung.jpg";

import heartReport from "../pdfs/links/healthy_heart.pdf";
import nutritionReport from "../pdfs/links/nutrition.pdf";
import digestiveReport from "../pdfs/links/digestive_health.pdf";
import coreReport from "../pdfs/links/core_strength.pdf";
import lungReport from "../pdfs/links/lung_health.pdf";

import privacyPolicy from "../pdfs/privacy_policy_2019.pdf";

const Footer = () => {
	return (
		<div id="footer" className="row justify-content-between">
				<div className="col-6 col-lg-2">
					<a href={heartReport} target="_blank" rel="noreferrer noopener">
						<div className="card bg-dark">
							<img src={heart} className="card-img-top" alt="report-icons" />
							<div className="card-text">{"Healthy Heart".toUpperCase()}</div>
						</div>
					</a>
				</div>
				<div className="col-6 col-lg-2">
					<a href={nutritionReport} target="_blank" rel="noreferrer noopener">
						<div className="card bg-dark">
							<img
								src={nutrition}
								className="card-img-top"
								alt="report-icons"
							/>
							<div className="card-text">{"Nutrition".toUpperCase()}</div>
						</div>
					</a>
				</div>
				<div className="col-6 col-lg-2">
					<a href={digestiveReport} target="_blank" rel="noreferrer noopener">
						<div className="card bg-dark">
							<img
								src={digestive}
								className="card-img-top"
								alt="report-icons"
							/>
							<div className="card-text">
								{"Digestive Health".toUpperCase()}
							</div>
						</div>
					</a>
				</div>
				<div className="col-6 col-lg-2">
					<a href={coreReport} target="_blank" rel="noreferrer noopener">
						<div className="card bg-dark">
							<img src={core} className="card-img-top" alt="report-icons" />
							<div className="card-text">{"Core Strength".toUpperCase()}</div>
						</div>
					</a>
				</div>
				<div className="col-6 col-lg-2">
					<a href={lungReport} target="_blank" rel="noreferrer noopener">
						<div className="card bg-dark">
							<img src={lung} className="card-img-top" alt="report-icons" />
							<div className="card-text">{"Lung Health".toUpperCase()}</div>
						</div>
					</a>
				</div>
				<div
					id="copyright-footer"
					className="d-flex bg-dark justify-content-between w-100"
				>
					<p className="my-auto">
						<a href={privacyPolicy} target="_blank" rel="noreferrer noopener">
							Privacy Policy |
						</a>{" "}
						The Fountain Spa
					</p>
					<p className="my-auto">© 2023</p>
				</div>
			</div>
	)
}

export default Footer;
