import React from "react";

import faqLogo from "../images/faq.jpg";

class FAQPage extends React.Component {
  render() {
    const loc = this.props.match.params.loc;
    return (
      <div id="faqpage">
        <div className="row justify-content-center">
          <div className="col-lg-8 ">
            <img className="img-fluid" src={faqLogo} alt="header" />
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-lg-8">
            <h4>Frequently Asked Questions:</h4>
            <b>What time is the Club open?</b>
            {loc === "glenrothes" && (
              <p>
                The Club opens at 7.00am and closes at 9.30pm Monday to Friday.
                Saturday & Sunday we open at 8am and close at 6pm. Certain
                restrictions are applicable to Off Peak Members.
              </p>
            )}
            {loc === "edinburgh" && (
              <p>
                The Club opens at 6.30am and closes at 9.30pm Monday to Friday.
                Saturday & Sunday we open at 8.00am and close at 7.00pm. Certain
                restrictions are applicable to Off Peak Members.
              </p>
            )}
            <b>How do I go about joining?</b>
            <p>
              It could not be easier. A few simple forms to fill in, schedule an
              appointment with a Fitness & Wellbeing Advisor and you could be
              using the Club today.
            </p>
            <b>When do I receive my Membership Card?</b>
            {loc === "glenrothes" && (
              <p>
                Your card is issued at the time you join. We take a quick photo
                and issue the card so you can use it there and then and on every
                visit thereafter.
              </p>
            )}
            {loc === "edinburgh" && (
              <p>
                As soon as your paperwork is completed we will issue with a
                Membership Card. We ask that this card is swiped each and every
                visit.
              </p>
            )}
            <b>How do I book Fitness Classes?</b>
            <p>
              Classes are available to all Members free of charge. Each Class
              can be booked up to 6 days in advance. Bookings can be made in
              person or by telephone.
            </p>
            <b>Am I signing up to a 12-month contract?</b>
            <p>
              No. All we ask is that you stay a minimum of 3 months and if you
              need to cancel, that you do this in writing giving one calendar
              months notice.
            </p>
            <b>Is there a minimum age for using the Gym & Fitness Classes?</b>
            <p>You have to be 16 years or older to use the Gym and Classes.</p>
            <br />
            <b>To join or for further information contact:</b>
            {loc === "glenrothes" && (
              <p>
                Fountain Spa leisure club,
                <br />
                Balgeddie House Hotel,
                <br />
                Balgeddie Way, Glenrothes, Fife, Scotland, KY6 3QA
                <br />
                Tel:{" "}
                <u>
                  <b style={{ color: "#1a82b8" }}>+44 (0) 1592 742727</b>
                </u>
                <br />
                Fax: +44 (0) 1592 621702
                <br />
                E-mail:{" "}
                <a href="mailto:spa@balgeddiehouse.com">
                  <u>
                    <b style={{ color: "#1a82b8" }}>spa@balgeddiehouse.com</b>
                  </u>
                </a>
              </p>
            )}
            {loc === "edinburgh" && (
              <p>
                The Fountain Spa leisure club,
                <br />
                Kings Manor Hotel,
                <br />
                100 Milton Road East, Edinburgh, Scotland EH15 2NP
                <br />
                Tel:{" "}
                <u>
                  <b style={{ color: "#1a82b8" }}>+44 (0) 131 669 0444</b>
                </u>
                <br />
                Direct: +44 (0)131 468 8001
                <br />
                Fax: +44 (0) 131 669 6650
                <br />
                E-mail:{" "}
                <a href="mailto:spa.manager@kingsmanor.com">
                  <u>
                    <b style={{ color: "#1a82b8" }}>
                      spa.manager@kingsmanor.com
                    </b>
                  </u>
                </a>
              </p>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default FAQPage;
