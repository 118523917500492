import React, { useEffect } from "react";
import QuestionAnswer from "../components/QuestionAnswer";

const HealthAndBeautyFAQPage = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div id='faqpage'>
			<div className='row'>
				<div className='col-lg-8'>
					<h4 style={{ color: "red", marginBottom: "30px" }}>
						Health & Beauty - Frequently Asked Questions:
					</h4>
					<QuestionAnswer
						style={{ marginBottom: "10px" }}
						question='What will the Balcony now look like?'
					>
						<p>
							We have arranged the Salon to conform with all current guidelines
							to protect both our staff and our customers.
						</p>
						<p>
							<b>Hand sanitizers</b> are positioned in every room and in the
							reception area.
						</p>
						<p>
							<b>Information signs</b> are displayed throughout to remind
							everyone of the guidance they must follow
						</p>
						<p>
							<b>Cleaning</b> is a main focus within the Salon. All
							non-essential items (magazines, brochures, soft furnishings) have
							been removed.
						</p>
						<p>
							<b>Protection</b> our staff will wear Personal Protective
							Equipment (PPE) to mitigate risks and screens will be used for
							treatments such as Manicures
						</p>
					</QuestionAnswer>
					<QuestionAnswer
						style={{ marginBottom: "10px" }}
						question='Will I need to wear a face covering?'
					>
						<p>
							Yes. We will be asking all customers to the Balcony Health &
							Beauty Salon to wear a face covering. This is a mandatory
							guideline for all close contact service. Should anyone arrive for
							a treatment without an appropriate face covering, we will be
							unable to deliver their treatment and may have to request payment.
						</p>
					</QuestionAnswer>
					<QuestionAnswer
						style={{ marginBottom: "10px" }}
						question='Will my Therapist be wearing PPE?'
					>
						<p>
							Yes. We have made available to all our Therapists a host of
							Personal Protective Equipment (PPE). These include: face masks,
							face shields, aprons and gloves. You should expect to see your
							Therapist wear these, dependant on the treatment you are
							receiving.
						</p>
					</QuestionAnswer>
					<QuestionAnswer
						style={{ marginBottom: "10px" }}
						question='Has my Therapist received additional training relating to COVID-19?'
					>
						<p>
							Yes. During our enforced closure Therapists have continued to
							develop their own skills and knowledge. Amongst this they have
							received training specifically on COVID-19 Prevention and on
							product use for the effective fight against COVID-19 in a Salon
							environment.
						</p>
					</QuestionAnswer>
					<QuestionAnswer
						style={{ marginBottom: "10px" }}
						question='How do I make a booking?'
					>
						<p>
							Bookings can be made by contacting us on 0131 468 8001. At this
							moment in time we are respectfully asking clients not to attempt
							bookings in person at the site. This will go a long way to
							avoiding the potential of crowds. Where we understand clients have
							a preference for certain Therapists, it is hoped that clients can
							receive treatments from either Therapist as we work towards
							reinstating our full availability.
						</p>
					</QuestionAnswer>
					<QuestionAnswer
						style={{ marginBottom: "10px" }}
						question='Will my contact details be retained by the business?'
					>
						<p>
							Yes. The details we take from you at the point of booking and
							during the treatment will be retained as specified by the Scottish
							Government for a period of 4 weeks. This information could prove
							vital if the Test & Protect protocols need to be entered into.
						</p>
					</QuestionAnswer>
					<QuestionAnswer
						style={{ marginBottom: "10px" }}
						question='How will I be asked to pay for my appointment?'
					>
						<p>
							We have installed protective screens at Reception and will be
							enforcing a face covering rule for both staff and clients. With
							these measures in place, we will accept payment on the day of the
							treatment by card. We strongly discourage cash payments at this
							time.
						</p>
					</QuestionAnswer>
					<QuestionAnswer
						style={{ marginBottom: "10px" }}
						question='How will the consultation period work?'
					>
						<p>
							When you make your booking, we will take your e-mail address so
							that we can send out a Consultation Form for your completion ahead
							of your treatment date. This should be returned ASAP. If we need
							any additional information, we will contact you ahead of the
							treatment date.
						</p>
						<p>
							A courtesy call will take place the day before your treatment to
							confirm you are still able to attend and to confirm that you are
							not suffering from any of the recognised symptoms of COVID-19:
						</p>
						<ul>
							<li>New continuous cough</li>
							<li>High temperature (Fever</li>
							<li>
								Loss of, or change in, your normal sense of taste or smell
								(Anosmia)
							</li>
						</ul>
					</QuestionAnswer>
					<QuestionAnswer
						style={{ marginBottom: "10px" }}
						question='Are the Treatment Rooms being thoroughly cleaned between appointments?'
					>
						<p>
							Yes. We recognise that a major risk in the spread of COVID-19 is
							the “touch points” used by numerous people. To tackle this and to
							promote the highest levels of hygiene; time has been allocated to
							every treatment for our Therapist to thoroughly clean their room
							after every appointment. In turn every client can be assured that
							the room they enter has been disinfected.
						</p>
					</QuestionAnswer>
					<QuestionAnswer
						style={{ marginBottom: "10px" }}
						question='When should I arrive for my treatment?'
					>
						<p>
							It is our aim to not have anyone waiting for an appointment within
							the Salon where social distancing can be difficult. To achieve
							this we respectfully ask clients to only arrive at their
							prescribed time. If you arrive early we may have to ask you to
							wait outdoors until the Therapist is ready. When arriving please
							check in at the Club reception where they will give further
							instructions. As an absolute minimum, we strongly suggest you aim
							not to arrive any more than 5 minutes before your allotted time.
						</p>
					</QuestionAnswer>
					<QuestionAnswer
						style={{ marginBottom: "10px" }}
						question='What if I am running late for my appointment?'
					>
						<p>
							e would respectfully request that you contact the Club on 0131 468
							8001 to inform us. We will examine all options to still have your
							treatment go ahead close to the original time. If no alternative /
							suitable time is available, we will base any cancellation payment
							on the individual circumstance.
						</p>
					</QuestionAnswer>
					<QuestionAnswer
						style={{ marginBottom: "10px" }}
						question='Can I bring a child to my appointment?'
					>
						<p>
							To operate in a fashion that reduces risks as far as possible we
							cannot allow for Children to accompany you for your appointment.
							We recognise and appreciate that this will for some prove more
							challenging in receiving any long-awaited treatment. However, the
							protection of our clients, staff and the wider community is a
							major player in our decision making.
						</p>
					</QuestionAnswer>
					<QuestionAnswer
						style={{ marginBottom: "10px" }}
						question='What if my circumstances change ahead of my appointment?'
					>
						<p>
							If you call the Salon within 24 hours of your arranged booking, we
							will look to accommodate a change of booking to an alternative
							date. If your changes are required through health reasons, we will
							endeavour to work with you to achieve the best outcome.
						</p>
					</QuestionAnswer>
					<QuestionAnswer
						style={{ marginBottom: "10px" }}
						question='Will there be refreshments available (water)?'
					>
						<p>
							As part of our efforts to reduce any risks associated with the
							potential spread of COVID-19 we will be withdrawing the
							complementary water provision. Bringing your own water bottle is
							permitted but this must be taken with you when you leave the
							Salon.
						</p>
					</QuestionAnswer>
					<QuestionAnswer
						style={{ marginBottom: "10px" }}
						question='Will there be toilets available?'
					>
						<p>
							Our toilet facility will be available. We would however, suggest
							that you use an alternative toilet prior to your visit.
						</p>
					</QuestionAnswer>
					<QuestionAnswer
						style={{ marginBottom: "10px" }}
						question='What treatments are not available?'
					>
						<p>
							Certain treatments have had to be temporarily withdrawn. This
							could be a result of the ancillary services not being available.
							and or the current guidance issued by the relevant bodies on
							safety protocols required when visiting the Salon. In each case we
							will work hard to give alternatives if at all possible.
						</p>
						<p>Currently we cannot deliver:</p>
						<ul>
							<li>Body Wraps</li>
							<li>Groupon Vouchers</li>
							<li>Spa Packages (Elemis Time Out) others are available</li>
						</ul>
						<p>
							This list is under constant review and we encourage clients to
							give us a call on 0131 468 8001 to discuss the current situation
							at any time.
						</p>
					</QuestionAnswer>
					<QuestionAnswer
						style={{ marginBottom: "10px" }}
						question='Will my Gift Voucher still be valid?'
					>
						<p>
							It is our intention to honour all Gift Vouchers who's validity has
							been cut short as a result of the Lockdown and the Salon being
							unavailable. We will review each case on an individual basis. If
							you have a Gift Voucher that has lapsed or been affected by the
							Lockdown please get in touch (0131 468 8001). We will look towards
							a suitable extension and if required alternative treatments.
						</p>
					</QuestionAnswer>
					<QuestionAnswer
						style={{ marginBottom: "10px" }}
						question='Is there a limit on the amount of treatments I can have in one appointment?'
					>
						<p>
							Initially we will not be looking towards imposing a cap on the
							treatments booked by any one client for any one time. We would
							request though that all clients give consideration to their fellow
							Salon users and look to spread their treatments in order that we
							can see as many clients in our opening weeks as possible. We will
							keep this situation under constant review.
						</p>
					</QuestionAnswer>
					<QuestionAnswer
						style={{ marginBottom: "10px" }}
						question='Should I remove all jewellery before my visit?'
					>
						<p>
							In the best interests of hygiene, we will be requesting that you
							remove any jewellery that would normally be taken off during your
							treatment, in advance of you attending the Salon. Those clients
							that book a Manicure – we would ask that you remove all hand and
							wrist jewellery before your visit. For massage remove neck
							jewellery.
						</p>
					</QuestionAnswer>
					<QuestionAnswer
						style={{ marginBottom: "10px" }}
						question='What personal belongings will I be allowed to bring?'
					>
						<p>
							We would like for all clients to arrive with an absolute minimum
							of personal belongings. This will assist in keeping the Salon as
							hygienic as possible. We understand that Mobile Phones are, in
							some cases, very important. As such these can be brought but
							should remain in a pocket and only used in an emergency. Purses,
							handbags, rucksacks should not be brought into the Salon. Water
							Bottles are permitted, but must be taken away after your
							treatment.
						</p>
					</QuestionAnswer>
					<QuestionAnswer
						style={{ marginBottom: "10px" }}
						question='Can I use the Leisure Facilities as a Health & Beauty customer?'
					>
						<p>
							At this time the Lesiure Club is being used by Members only. With
							each session requiring advanced booking, we want to give as much
							availability to our paying members as possible. This situation
							will be under constant review. We encourage you to enquire further
							for any change.
						</p>
					</QuestionAnswer>
					<QuestionAnswer
						style={{ marginBottom: "10px" }}
						question='Are Complementary Therapies available?'
					>
						<p>
							It is our intention to resume all services permissible by the
							Scottish Government that were associated with our Health & Beauty
							Salon prior to lockdown. These services include:
						</p>
						<ul>
							<li>Sports & Remedial Massage</li>
							<li>Chiropractor</li>
							<li>Physiotherapy</li>
							<li>Podiatry</li>
						</ul>
					</QuestionAnswer>
					<QuestionAnswer
						style={{ marginBottom: "10px" }}
						question='How do I make a booking for a Complementary Therapy?'
					>
						<p>For Sports Massage please contact Lynsey on: 07849 300 126.</p>
						<p>
							For our Chiropractor Clinic please contact Colin on: 07510 231
							913.
						</p>
					</QuestionAnswer>
				</div>
			</div>
		</div>
	);
};

export default HealthAndBeautyFAQPage;
