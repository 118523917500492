import React, { useCallback, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const images = [
  require("../images/slideshow_1.jpg"),
  require("../images/slideshow_2.jpg"),
  require("../images/slideshow_4.jpg"),
  require("../images/slideshow_5.jpg"),
  require("../images/slideshow_6.jpg"),
  require("../images/slideshow_7.jpg"),
  require("../images/hot_stone.jpg"),
];

const HomePage = () => {
  const [image, setImage] = useState(0);

  const changeImage = useCallback(
    (direction = "right") => {
      const adjust = direction === "right" ? 1 : -1;
      setImage(
        (((image + adjust) % images.length) + images.length) % images.length
      );
    },
    [image]
  );

  useEffect(() => {
    const intervalID = setInterval(changeImage, 5000);
    return () => clearInterval(intervalID);
  }, [changeImage]);

  return (
    <div id="homepage">
      <div className="row">
        <div className="col-12 col-lg-7">
          <div id="image-scroll">
            <span className="left" onClick={() => changeImage("left")}>
              <FontAwesomeIcon icon="arrow-alt-circle-left" />
            </span>
            <img className="img-fluid" src={images[image]} alt="spa images" />
            <span className="right" onClick={() => changeImage("right")}>
              <FontAwesomeIcon icon="arrow-alt-circle-right" />
            </span>
          </div>
          <h4>Welcome to The Fountain Spa.</h4>
          <p>
            The Fountain Spa leisure club can be found at the{" "}
            <a
              href="http://www.balgeddiehouse.com/"
              target="_blank"
              rel="noreferrer noopener"
            >
              Balgeddie House Hotel
            </a>
            , Glenrothes. A family ran business dedicated to delivering a high standard
            of product and care, our leisure club is a Members only facility.
            Through comprehensive service and facilities our leisure club has
            become very successful at reaching the goals of our Members.
          </p>
          <hr />
          <h5>Edinburgh - Fountain Spa</h5>
          <p>The Fountain Spa located at the Kings Manor Hotel, Edinburgh, has been rebranded as <a
              href="https://oasisleisurespa.co.uk/"
              target="_blank"
              rel="noreferrer noopener"
            >
              Oasis Leisure Club & Spa
            </a>. Please follow the link to find out more.</p>
        </div>
        <div className="col-12 col-lg-5">
          <h5>Main Features</h5>
          <ul id="main-features">
            <div className="row">
              <div className="col-5">
                <li>Fitness Suite</li>
                <li>Studio Classes</li>
                <li>Swimming Pool</li>
              </div>
              <div className="col-7">
                <li>Sauna</li>
                <li>Steam Room</li>
              </div>
            </div>
          </ul>
          <hr />
          <h5>Exercise</h5>
          <p>
            Our fully equipped, air conditioned Fitness Suites allows for
            comfortable exercise whatever your goals. With guidance from our
            qualified staff there should be no limitations to what can be
            achieved. Regular reviews and Programme prescription are available
            and Free.
          </p>
          <hr />
          <h5>Relaxation</h5>
          <p>
            Can be found in our Swimming Pools. With both Sauna and Steam Rooms
            this area of the Club is an ideal escape from everyday life. With
            our Pools being a constant depth (1.2m) and between 17 and 20 metres
            in length, there is plenty room to integrate exercise with fun.
          </p>
          <hr />
          <h5>Classes</h5>
          <p>
            Workout with friends with our comprehensive and Free Class
            Timetable. Get motivated with our experienced Instructors in our
            purpose built Studios. Hi energy to relaxing posses, our Timetable
            offers it all.
          </p>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
