import React from "react";

class FitnessPage extends React.Component {
  render() {
    const loc = this.props.match.params.loc;
    return (
      <div id="fitnesspage">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <h4>Fitness Suite</h4>
            {loc === "edinburgh" && (
              <p>
                Our fully equipped, air conditioned Fitness Suite allows for
                comfortable exercise whatever your goals are. With guidance from
                our qualified staff, there should be no limitations to what can
                be achieved. So if it’s calories you’re wishing to burn or
                muscles you’re wishing to tone, our gym provides the ideal
                environment.{" "}
              </p>
            )}
            {loc === "glenrothes" && (
              <p>
                Our fully equipped, air conditioned Fitness Suite allows for
                comfortable exercise whatever your goals are. With guidance from
                our qualified staff, there should be no limitations to what can
                be achieved. So if it’s calories you’re wishing to burn or
                muscles you’re wishing to tone, our gym provides the ideal
                environment.
              </p>
            )}
          </div>
        </div>
        <hr />
        <div className="row align-items-center">
          <div className="col-lg-5">
            <b>Exercise Equipment:</b>
            {loc === "glenrothes" && (
              <ul>
                <li>Treadmills</li>
                <li>Cross Trainer</li>
                <li>Upright Bike</li>
                <li>Recumbent Bike</li>
                <li>Rower</li>
                <li>Free Weights</li>
                <li>Fixed Weights</li>
                <li>Versa Climber</li>
                <li>Ski Erg</li>
              </ul>
            )}
            {loc === "edinburgh" && (
              <ul>
                <li>Treadmills</li>
                <li>Cross Trainer</li>
                <li>Bikes</li>
                <li>Rowers</li>
                <li>Free Weights</li>
                <li>Fixed Weights</li>
                <li>Swiss Ball</li>
                <li>Versa Climber</li>
              </ul>
            )}
            <b>Additional Features:</b>
            <ul>
              <li>Free Fitness Programmes</li>
              <li>Personal Training</li>
            </ul>
          </div>
          <div className="col-lg-7">
            <img
              className="img-fluid"
              alt="header"
              src={require("../images/footer_core.jpg")}
            />
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-lg-12">
            <p>
              Receive a free of charge Health Screen with a health and lifestlye
              questionnaire that explores your past and current health status.
              The information gathered will assist the Fitness & Wellbeing
              Advisor in developing an appropriate Fitness Programme
              specifically for you. Fitness & Wellbeing Advisors will always be
              on hand to discuss programme issues and perform reviews on a
              regular basis. This will ensure that programmes are refreshed and
              take into account goals that have been achieved or periods of
              inactivity
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default FitnessPage;
