import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const QuestionAnswer = (props) => {
	const [showing, setShowing] = useState(false);

	const caretIcon = (
		<FontAwesomeIcon
			icon={showing ? "caret-down" : "caret-right"}
			style={{
				fontSize: "20px",
				color: "#343a40",
				marginRight: "10px",
			}}
		/>
	);

	function toggleShowQuestion() {
		setShowing(!showing);
	}

	return (
		<div style={props.style || {}}>
			<div
				style={{ display: "flex" }}
				className="link noselect"
				onClick={toggleShowQuestion}
			>
				{caretIcon}
				<b>{props.question}</b>
			</div>
			{showing && props.children}
		</div>
	);
};

export default QuestionAnswer;
