import React from "react";
import QuestionAnswer from "../components/QuestionAnswer";

const CovidFAQPage = () => {
	return (
		<div id='faqpage'>
			<div className='row'>
				<div className='col-lg-8'>
					<h4 style={{ color: "red", marginBottom: "30px" }}>
						COVID-19 - Frequently Asked Questions:
					</h4>
					<QuestionAnswer
						style={{ marginBottom: "10px" }}
						question='What we’ve done while we were closed.'
					>
						<p>
							Throughout the period of closure, we ran through a number of
							operational models to ensure that when we could re-open, that we
							did so in a safe environment for both our staff and our members.
							These models have brought us to consider:
						</p>
						<ul>
							<li>
								<b>Social Distance</b> - Promoting social distancing throughout
								the club and through every member’s journey.
							</li>
							<li>
								<b>Cleaning</b> - Introducing comprehensive cleaning regimes.
								Increased cleaning of the Gym equipment. Introducing a “break”
								between classes to allow for thorough cleaning of Studio
								equipment.
							</li>
							<li>
								<b>Sanitizers</b> - Installing additional sanitizing stations.
							</li>
							<li>
								<b>New Procedures</b> - Introducing a new procedure that will
								see Gym and Studio equipment cleaned prior to and after each
								individual use by the user.
							</li>
							<li>
								<b>Protection</b> - Screens have been installed at Reception for
								the protection of our staff and members.
							</li>
							<li>
								<b>Education</b> - We have ensured our staff are up-to-date with
								education on the prevention of the virus spread and the
								protection measures required for a safe environment.
							</li>
						</ul>
					</QuestionAnswer>
					<QuestionAnswer
						style={{ marginBottom: "10px" }}
						question='How will The Fountain Spa implement social distancing?'
					>
						<ul>
							<li>
								<b>Numbers</b> - In order to ensure adequate spacing as outlined
								by the Government, we will limit the number of Members in the
								Gym, Pool and Studio at any one time. As a consequence, we will
								also be respectfully asking Members to limit their visits to a
								maximum of 2 sessions per day.
							</li>
							<li>
								<b>Swimming</b> - To comply with the advice from the Government
								and ensure all users are given the appropriate space, we will be
								asking for swimming sessions to be booked via telephone. These
								sessions will be for a predetermined time (Club specific).
							</li>
							<li>
								<b>Classes</b> - We are amending all classes to a 45-minute time
								slot. There will be a 15-minute period for those in a class to
								leave and for the next class to arrive. We are requesting all
								class attendees to arrive no earlier than 5-minutes before the
								class start time.
							</li>
							<li>
								<b>Changing Rooms</b> - These will be available for limited use.
								We encourage users to arrive exercise ready and return home for
								showering. However, for those that require use of the Changing
								Rooms, they will be available with Social Distancing and maximum
								capacity considered.
							</li>
							<li>
								<b>Signage</b> - The club will sport a number of information
								signs as a continued reminder for all users to act courteously
								and in line with Government Health guidance.
							</li>
							<li>
								<b>Gym</b> - Where required, equipment will be spaced to meet
								the relevant social distancing guidance.
							</li>
						</ul>
					</QuestionAnswer>
					<QuestionAnswer
						style={{ marginBottom: "10px" }}
						question='Is it safe to return to The Fountain Spa?'
					>
						<p>
							Since the closure of the Club on December 26th 2020, we have been
							monitoring all channels to ensure that we comply with all safety
							protocols and guidance. Our clientele is such that their health
							and wellbeing is of importance to them. This gives further
							confidence that all measures to mitigate any remaining risk will
							be followed to the letter. It is noteworthy that any deviation
							from our protocols by any member will be met with a withdrawal of
							their membership.
						</p>
					</QuestionAnswer>
					<QuestionAnswer
						style={{ marginBottom: "10px" }}
						question='Will I be required to wear any face covering or other PPE?'
					>
						<p>
							We continue to follow the guidance set by the Scottish Government.
							Face coverings in public spaces are mandatory. Whilst exercising,
							face coverings are not recommended - but you should remain
							vigilant to social distancing and replace your face covering
							whilst moving around the Club and between venues / equipment.
						</p>
					</QuestionAnswer>
					<QuestionAnswer
						style={{ marginBottom: "10px" }}
						question='Will the club be providing Hand Sanitizer?'
					>
						<p>
							We have installed a number of dispensers at key points throughout
							the club for your use and reassurance. We have units positioned
							immediately upon entry to the club, for use while entering and
							when leaving. We have added more dispensers to both the Studio and
							the Gym. New units are available within the Changing Rooms and on
							Poolside. We have looked to have sanitizer units near every touch
							point you may encounter during your visit.
						</p>
					</QuestionAnswer>
					<QuestionAnswer
						style={{ marginBottom: "10px" }}
						question='Will there be changes to the club’s opening hours?'
					>
						<p>
							It is our aim to get back to “normal” as soon as it can be
							achieved. Where we hope utilisation of the Club(s) returns
							promptly, we are also aware that many of our members will also be
							facing challenges adapting to a return to work, child care, and
							emerging from a prolonged lockdown. Initially our opening hours
							will differ between Clubs. For up to date information it will be
							best to contact your Club direct – Edinburgh 0131 468 8001 or
							Glenrothes 01592 742 727. Hours are continually subject to change.
						</p>
					</QuestionAnswer>
					<QuestionAnswer
						style={{ marginBottom: "10px" }}
						question='Will there be changes to the Class Timetable?'
					>
						<p>
							Our revised Timetable is now available on our Website. It is our
							aim to deliver close to what we did previously, but we must adopt
							a suitable break between classes to ensure hygiene levels are
							maintained. Classes will be 45-minutes in duration, with
							attendance figures set to meet with social distancing guidance.
						</p>
					</QuestionAnswer>
					<QuestionAnswer
						style={{ marginBottom: "10px" }}
						question='Will there be a time limit on my visits to the club?'
					>
						<p>
							We are committed to delivering a service to all our members. With
							social distancing and capacity levels to be considered, we will be
							asking members to limit their use of the club to:
						</p>
						<ul>
							<li>
								<b>Classes</b> - No more than 2 per day.
							</li>
							<li>
								<b>Gym Sessions</b> - No more that 2 per day.
							</li>
							<li>
								<b>Steam & Sauna Room</b> - Restricted to one session per day.
							</li>
							<li>
								<b>Swimming</b> - No more than 2 seesions per day. Note: for
								maximum opportunity for all – sessions are not permitted back to
								back
							</li>
						</ul>
						<p>
							These measures should allow for all the Fountain Spa's Members to
							use the Club comfortably. These conditions will be constantly
							reviewed.
						</p>
					</QuestionAnswer>
					<QuestionAnswer
						style={{ marginBottom: "10px" }}
						question='Do I need to book classes, swimming and gym sessions?'
					>
						<p>
							All sessions currently need to be booked in advance. Bookings are
							available 6-days in advance of the event:
						</p>
						<ul>
							<li>
								<b>Classes</b> – typically 45 minutes long Gym
							</li>
							<li>
								<b>Gym Session</b> - typically 45 minutes long
							</li>
							<li>
								<b>Swimming</b> - durations vary, please contact your club for
								specific details
							</li>
							<li>
								<b>Sauna & Steam Room</b> - durations vary, please contact your
								club for specific details
							</li>
						</ul>
						<p>
							Booking should, ideally, be made via telephone to avoid potential
							crowding at Reception. Users should arrive no earlier than
							5-minutes before their booked session(s).
						</p>
					</QuestionAnswer>
					<QuestionAnswer
						style={{ marginBottom: "10px" }}
						question='What should I bring when coming to the club?'
					>
						<p>
							Our aim is to keep things minimal. This will reduce the
							opportunity of contamination, and give comfort to all users that
							their visit is safe.
						</p>
						<ul>
							<li>
								<b>Swimming</b> - Arrive swim ready so that you can remove your
								clothing on Poolside. After your session you can access the
								Changing Rooms to shower and change. Bring your own towel.
							</li>
							<li>
								<b>Sauna</b> - Arrive ready for the sauna so that you can remove
								your clothing on Poolside. Access to the lockers is permitted to
								store valuables if required. You MUST bring your own towel to
								sit on during your session.
							</li>
							<li>
								<b>Steam Room</b> - Arrive ready for the Steam Room so that you
								can remove your clothing on Poolside. Access to the lockers is
								permitted to store valuables if required.
							</li>
							<li>
								<b>Classes</b> - Arrive ready to do the class. Coat hooks will
								be available in the Studio.
							</li>
							<li>
								<b>Gym</b> - Arrive ready to do your session.
							</li>
							<li>
								<b>Changing Rooms</b> - These are available for limited use. If
								your circumstance is that you need access, please ensure you
								practice social distancing, clean your area before and after,
								and are mindful of the capacity level.
							</li>
						</ul>
					</QuestionAnswer>
					<QuestionAnswer
						style={{ marginBottom: "10px" }}
						question='Will the Changing Rooms be open?'
					>
						<p>
							Changing Rooms will be available for limited use. It remains our
							strong preference that users arrive “exercise ready”. However,
							with the provision being permitted and our knowing that some
							Members require the Changing Rooms, these will be made available
							from July 19th 2021. Please ensure you follow the mitigation
							measures we have in place – capacity, social distance and
							cleaning. Pool users should continue to arrive “Swim Ready” so as
							not to lose time from within their session. Access to the Pool
							will continue to be through Reception only.
						</p>
					</QuestionAnswer>
					<QuestionAnswer
						style={{ marginBottom: "10px" }}
						question='Will you be providing a Towel service?'
					>
						<p>
							From July 19th 2021, towel hire service will resume. NOTE: these
							towels MUST NOT be used as sweat towels. Paper towels are
							available throughout the facility with disinfectant spray. These
							should be used for cleaning. Towels MUST NOT be used to wipe down
							equipment.
						</p>
					</QuestionAnswer>
					<QuestionAnswer
						style={{ marginBottom: "10px" }}
						question='Will the Sauna, Steam Room, and Spa Bath be available?'
					>
						<p>
							Both our Sauna and Steam Room have reopened. To operate these
							safety, we will be admitting a maximum of 2 Members per session.
							These sessions can be easily booked by calling your Club.
							Throughout your visit we respectfully ask you to practice good
							hygiene and shower before and after use. Please bring your own
							Towel for Sauna use (sitting on). We will ask for you to spray
							your indicated seat prior to and after use.
						</p>
						<p>The Spa Bath currently remains closed.</p>
					</QuestionAnswer>
					<QuestionAnswer
						style={{ marginBottom: "10px" }}
						question='Will PT sessions be available?'
					>
						<p>
							These are available and will be delivered in accordance with
							relevant guidelines.
						</p>
					</QuestionAnswer>
					<QuestionAnswer
						style={{ marginBottom: "10px" }}
						question='Will the club be delivering Health Screens?'
					>
						<p>
							This service will be dependent on recourses available at your
							Club. To enquire further, please contact your Club on (Edinburgh)
							0131 468 8001 or (Glenrothes) 01592 742 727.
						</p>
					</QuestionAnswer>
					<QuestionAnswer
						style={{ marginBottom: "10px" }}
						question='Will the Tennis Court be available?'
					>
						<p>
							This facility is available on a booking basis. Bookings should be
							made via telephone on 0131 468 8001. We request you use your own
							equipment where possible. Any equipment loaned out (rackets)
							should be cleaned before and after use with disinfectant.
						</p>
					</QuestionAnswer>
					<QuestionAnswer
						style={{ marginBottom: "10px" }}
						question='Will the Health & Beauty Salon and Complementary Therapies by open?'
					>
						<p>Both these services are now open.</p>
					</QuestionAnswer>
					<QuestionAnswer
						style={{ marginBottom: "10px" }}
						question='Will there be a service for food and drink at the club?'
					>
						<p>
							The Hotel’s Bistro and Brasserie will be available and will be
							operating in accordance with Government Guidelines.
						</p>
					</QuestionAnswer>
					<QuestionAnswer
						style={{ marginBottom: "10px" }}
						question='When will my Membership restart?'
					>
						<p>All memberships were reactivated on April 26th 2021.</p>
					</QuestionAnswer>
					<QuestionAnswer
						style={{ marginBottom: "10px" }}
						question='Will my Annual Membership be extended?'
					>
						<p>
							The period of time between December 26th and April 26th (4 months)
							will be added to the renewal date of your Annual Membership. For
							any Annual Membership that was set for renewal between the date
							range of December 26th and April 26th, an appropriate period will
							be added to your renewal date to represent the time lost. Please
							note that those Members affected by travel restrictions (not able
							to attend the Club whilst it was in Level 3) a period of
							additional time will be credited to your renewal date.
						</p>
					</QuestionAnswer>
					<QuestionAnswer
						style={{ marginBottom: "10px" }}
						question='Are Children permitted to return when the Club re-opens?'
					>
						<p>
							Yes. Please note: Children under the age of 15 years must occupy
							the same Swimming lane as their guardian. A maximum of 2 children
							can accompany one guardian. Children over 15 years can either
							share a lane or be booked in an individual lane subject to
							availability.
						</p>
					</QuestionAnswer>
					<QuestionAnswer
						style={{ marginBottom: "10px" }}
						question='What if I am not ready to return?'
					>
						<p>
							If you feel that you are not ready to return to the club, we can
							arrange for your membership to be Frozen. To confirm this is your
							preference, we respectfully request that you inform us in writing
							through{" "}
							<a href='mailto:spa.manager@kingsmanor.com'>
								spa.manager@kingsmanor.com
							</a>
							. From there, we will confirm back to you the terms and conditions
							of our Freeze option.
						</p>
					</QuestionAnswer>
					<QuestionAnswer
						style={{ marginBottom: "10px" }}
						question='Can I still buy Guest Passes?'
					>
						<p>
							Guest passes can still be purchased from the Club's reception. We
							have adapted the use of these passes temporarily to ensure
							priority is given to the Membership. With space potentially
							limited in some areas, we respectfully ask Guests to only book
							sessions on the day they intend to visit the Club.
						</p>
					</QuestionAnswer>
					<QuestionAnswer
						style={{ marginBottom: "10px" }}
						question='When will all restrictions be lifted?'
					>
						<p>
							The exact timeframe for this is not known. However we anticipate
							that August 9th will bring significant changes. We will continue
							to monitor the guidance from Government and Trade bodies. When
							they inform us, that measures can be amended, we will act
							accordingly. At all times we will act with the safety of members
							and staff at the forefront of our thoughts.
						</p>
					</QuestionAnswer>
					<QuestionAnswer
						style={{ marginBottom: "10px" }}
						question='Will my subscription be altered at all?'
					>
						<p>
							After your membership is restarted (April 26th), regular fees will
							be collected on or around the 1st of each month
						</p>
					</QuestionAnswer>
					<QuestionAnswer
						style={{ marginBottom: "10px" }}
						question='Will there be toilets available within the club?'
					>
						<p>
							With a return of the Changing Rooms, the toilets within these will
							now be available. Please ensure you comply with the maximum
							occupancy for the Changing Rooms at all times.
						</p>
					</QuestionAnswer>
				</div>
			</div>
		</div>
	);
};

export default CovidFAQPage;
