import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import "./App.css";

import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import HomePage from "./pages/HomePage";
import FitnessPage from "./pages/FitnessPage";
import WetsidePage from "./pages/WetsidePage";
import ClassesPage from "./pages/ClassesPages";
import FAQPage from "./pages/FAQPage";
import MembershipPage from "./pages/MembershipPage";
import ContactPage from "./pages/ContactPage";
import BeautyPage from "./pages/BeautyPage";
import TherapiesPage from "./pages/TherapiesPage";
import PersonalTrainingPage from "./pages/PersonalTrainingPage";
import CovidFAQPage from "./pages/CovidFAQPage";
import HealthAndBeautyFAQPage from "./pages/HealthAndBeautyFAQPage";
import CollapsedNavBar from "./components/CollapsedNavBar";

const App = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [props.location]);

  return (
    <div className="container">
      <Route path="" component={NavBar} />
      <div className="content">
        <Route
          exact
          path="/menu"
          render={(props) => <CollapsedNavBar {...props} />}
        />
        <Route exact path="" render={(props) => <HomePage {...props} />} />
        <Route
          exact
          path="/covid-faq"
          render={(props) => <CovidFAQPage {...props} />}
        />
        <Route
          exact
          path="/health-and-beauty-faq"
          render={(props) => <HealthAndBeautyFAQPage {...props} />}
        />
        <Route
          exact
          path="/:loc/fitness"
          render={(props) => <FitnessPage {...props} />}
        />
        <Route
          exact
          path="/:loc/wetside"
          render={(props) => <WetsidePage {...props} />}
        />
        <Route
          exact
          path="/:loc/classes"
          render={(props) => <ClassesPage {...props} />}
        />
        <Route
          exact
          path="/:loc/health_and_beauty"
          render={(props) => <BeautyPage {...props} />}
        />
        <Route
          exact
          path="/:loc/therapies"
          render={(props) => <TherapiesPage {...props} />}
        />
        <Route
          exact
          path="/:loc/personal_training"
          render={(props) => <PersonalTrainingPage {...props} />}
        />
        <Route
          exact
          path="/:loc/faq"
          render={(props) => <FAQPage {...props} />}
        />
        <Route
          exact
          path="/:loc/membership"
          render={(props) => <MembershipPage {...props} />}
        />
        <Route
          exact
          path="/:loc/contact"
          render={(props) => <ContactPage {...props} />}
        />
      </div>
      <Route path="" component={Footer} />
    </div>
  );
};

export default App;
