import React from "react";
import ReactDOM from "react-dom";
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import "./bootstrap.min.css";
import App from "./App";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
	faArrowAltCircleLeft,
	faArrowAltCircleRight,
	faCaretRight,
	faCaretDown,
} from "@fortawesome/free-solid-svg-icons";

library.add(
	faArrowAltCircleLeft,
	faArrowAltCircleRight,
	faCaretRight,
	faCaretDown
);

ReactDOM.render(
	<Router>
		<Switch>
			<Route path="" component={App} />
		</Switch>
	</Router>,
	document.getElementById("root")
);
