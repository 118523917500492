import React from "react";

import image from "../images/yoga_mat.jpeg";

import eTimetable from "../pdfs/edinburgh_timetable.pdf";
import gTimetable from "../pdfs/glenrothes_timetable.pdf";

const ClassesPage = (props) => {
  const loc = props.match.params.loc;

  return (
    <div id="classespage">
      <div className="row justify-content-center">
        <div className="col-lg-8">
          <h4>Classes</h4>
          {loc === "glenrothes" && (
            <p>
              We operate a diverse programme of Group Exercise classes, with a
              full timetable of class options each week. All Instructors have
              been selected for their expertise in teaching a particular style
              of class with passion and flair. All our classes are structured to
              cater for all abilities, so whether it's your first class or
              you're a regular class attendee, you know you will fit straight
              in.
            </p>
          )}
          {loc === "edinburgh" && (
            <p>
              We operate a Class Booking Systems that allows Members to book
              classes 6 days in advance. This can either be done at Reception or
              over the phone. All Members should arrive in plenty time for their
              classes and register at Reception. Class attendees are advised to
              bring a bottle of water to remain hydrated
            </p>
          )}
          {loc === "glenrothes" && (
            <p>
              Our purpose built studio is fitted with a high quality sound
              system, is air conditioned and has a wooden sprung floor. This
              creates a pleasant and safe environment that allows Members to
              enjoy and benefit from our instructor-led classes. We operate a
              class booking system that allows Members to book classes 6 days in
              advance. This can either be done at Reception or over the phone.
              All Members should arrive in plenty of time for their classes and
              register at the Club’s reception. Class attendees are advised to
              bring a bottle of water to remain hydrated.
            </p>
          )}
          {loc === "edinburgh" && (
            <p>
              Our purpose-built Studio is fitted with a high quality sound
              system, is air conditioned and has wooden sprung floor-boards.
              This creates a pleasant and safe environment that allows Members
              to enjoy and benefit from our instructor-led classes. A number of
              the Classes at the Fountain Spa are delivered by external
              instructors who have been sourced for their experience and
              charisma and who will provide Members with a unique and rewarding
              class experience. We also have Fitness & Wellbeing Advisors on
              site who can advise you on which Classes are best suited to your
              personal tastes and exercise goals.
            </p>
          )}
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-lg-7">
          {loc === "glenrothes" && (
            <>
              <p>
                Please check our{" "}
                <a href={gTimetable} target="_blank" rel="noreferrer noopener">
                  Class Timetable page
                </a>{" "}
                for a full list of scheduled classes and start times.
              </p>
            </>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-lg-7">
          {loc === "edinburgh" && (
            <>
              <p>
                Please check our{" "}
                <a href={eTimetable} target="_blank" rel="noreferrer noopener">
                  Class Timetable page
                </a>{" "}
                for a full list of scheduled classes and start times.
              </p>
            </>
          )}
          <b>Classes:</b>
          {loc === "glenrothes" && (
            <ul>
              <li>
                <b>Body Sculpt</b> - A full body low impact workout using a
                mixture of training techniques and equipment to assist in
                sculpting your overall fitness.
              </li>
              <li>
                <b>Yoga</b> - Promotes both mental and physical well-being.
                Movements, meditation and breathing techniques combine and
                intertwine to bring an exercise regime that leaves you balanced
                for the challenges of today’s world.
              </li>
              <li>
                <b>Spin 35</b> - An indoor cycling class that has something for
                everyone. Intervals, Hills, Sprints, Climbs and Jumps. All
                delivered with handpicked music to motivate and inspire.
                Increases endurance and burns calories.
              </li>
              <li>
                <b>Body Conditioning</b> - A full body workout combining cardio
                and resistance exercises. Exercise that includes aerobics,
                floor-work, weights and resistance training. You're guaranteed a
                varied class that will hit those areas that other workouts miss.
              </li>
              <li>
                <b>Aqua</b> - A challenging water-based workout, fit for
                everyone. Non-impact, so ideal for those with joint or mobility
                issues. Uses body weight, water resistance and equipment to give
                a fun workout that’s worthwhile.
              </li>
              <li>
                <b>Pilates</b> - Pilates consists of a number of exercises
                designed to promote flexibility, muscular endurance, core
                strength and postural alignment. For 100 years this discipline
                has helped countless people.
              </li>
              <li>
                <b>Body Comba</b>t - Punches & kicks galore, all towards the end
                goal of a total body workout. Motivational and recognisable
                music helps you through this multi stage class. Non-contact &
                fun. Nothing less will do.
              </li>
              <li>
                <b>Body Pump</b> - Lean, toned and fit. These 3 words sum up
                this barbell exercise class. Achieves Gym results quicker than
                your Gym routine. Targets the whole body – arms, legs, chest,
                back & shoulders. The complete workout.
              </li>
              <li>
                <b>Zumba</b> - Build and improve your cardiovascular system
                through the fun of dance. International and Latin music, slow
                and fast rhythms and lots and lots of fun. A great workout for
                all age groups.
              </li>
              <li>
                <b>Body Balance</b> - A unique workout which incorporates Yoga,
                Tai Chi and Pilates to help improve flexibility, build strength
                and leave you feeling relaxed and calm. This holistic workout
                brings the body and mind into a state of harmony.
              </li>
              <li>
                <b>LBT</b> - Looking to tone your legs, bum and tummy – this is
                the class for you. Squats, leg lifts, lunges and body weight
                resistance are a few of the techniques used. Here its quality
                rather than quantity that counts.
              </li>
              <li>
                <b>Stretch & Core</b>- A class designed to increase your
                flexibility and tighten up your abdominals. This class aims to
                tone your stomach and help you improve your movement/range of
                motion and relieve stress.
              </li>
            </ul>
          )}
          {loc === "edinburgh" && (
            <ul>
              <li>
                <b>Functional Fitness</b>- A high-intensity, full body workout
                that continues to burn calories after you leave. The class uses
                a variety of timed intervals combining muscular endurance and
                cardio vascular work with dynamic movements.
              </li>
              <li>
                <b>Yoga</b> - Promotes both mental and physical well-being.
                Movements, meditation and breathing techniques combine and
                intertwine to bring an exercise regime that leaves you balanced
                for the challenges of today’s world.
              </li>
              <li>
                <b>Spin 35</b> - An indoor cycling class that has something for
                everyone. Intervals, Hills, Sprints, Climbs and Jumps. All
                delivered with handpicked music to motivate and inspire.
                Increases endurance and burns calories.
              </li>
              <li>
                <b>Aqua</b> - A challenging water-based workout, fit for
                everyone. Non-impact so ideal for those with joint or mobility
                issues. Uses body weight, water resistance and equipment to give
                a fun workout that’s worthwhile.
              </li>
              <li>
                <b>Pilates</b> - Pilates consists of a number of exercises
                designed to promote flexibility, muscular endurance, core
                strength and postural alignment. For 100 years this discipline
                has helped countless people.
              </li>
              <li>
                <b>Body Combat</b> - Punches & kicks galore, all towards the end
                goal of a total body workout. Motivational and recognisable
                music helps you through this multi stage class. Non-contact &
                fun. Nothing less will do.
              </li>
              <li>
                <b>Body Pump</b> - Lean, toned and fit. These 3 words sum up
                this barbell exercise class. Achieves Gym results quicker than
                your Gym routine. Targets the whole body – arms, legs, chest,
                back & shoulder. The complete workout.
              </li>
              <li>
                <b>Zumba</b> - Build and improve your cardiovascular system
                through the fun of dance. International and Latin music, slow
                and fast rhythms and lots and lots of fun. A great workout for
                all age groups.
              </li>
              <li>
                <b>Fitness Pilates</b> - More of a functional approach to a
                traditional Pilates class. It still applies the main principles,
                but also involves more functional movements for everyday life.
              </li>
              <li>
                <b>LBT</b> - Looking to tone your legs, bum and tummy – this is
                the class for you. Squats, leg lifts, lunges and body weight
                resistance are a few of the techniques used. Here its quality
                rather than quantity that counts.
              </li>
              <li>
                <b>Totally Shredded</b> - From 2014 when Totally Shredded was
                first showcased, it has not stopped growing in popularity. An
                exercise class with a vision to inspire those that attend and
                have them all smiling while they workout. An ethos of no matter
                who you are, you can achieve your dreams. There are no limits to
                this class. Full of energy and movement, this class has jumping,
                punching, squatting and clapping all to the motivating sound of
                good music.
              </li>
              <li>
                <b>Engine</b>- A combination of cardiovascular and functional
                exercise over two 10 minute rounds. All levels of fitness
                welcome.
              </li>
              <li>
                <b>Flex & Stretch</b>- Low intensity exercise class, focusing on
                improving lower body flexibility and Range Of Motion through a
                wide variety of mobility exercises and static stretching
                routines.
              </li>
            </ul>
          )}
        </div>
        <div className="col-lg-5">
          <img className="img-fluid" alt="header" src={image} />
        </div>
      </div>
    </div>
  );
};

export default ClassesPage;
