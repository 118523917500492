import React from "react";

import background from "../images/therapies.jpg";
import brochure from "../pdfs/balcony_brochure.pdf";

const TherapiesPage = (props) => {
  return (
    <div id="therapiespage">
      <div className="row justify-content-center">
        <div className="col-lg-8">
          <img className="img-fluid mb-4" src={background} alt="header" />
          <h4>Complementary Therapies</h4>
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-12 col-md-6">
          <b>Sports Massage</b>
          <p>
            Not just for Sports Stars. This is an ideal treatment to alleviate
            recurring pain, tension, aches and pains experienced by all. Sports
            Massage looks to manipulate and rehabilitate muscles, tendons and
            ligaments. This is an ideal treatment if you are injured, competing
            or simply tense. Available with Lynsey & David.
          </p>
        </div>
        <div className="col-12 col-md-6">
          <b>Indian Head Massage</b>
          <p>
            A treatment that concentrates on the Head, Neck and Shoulders.
            Focusing on these areas makes this treatment ideal for targeting the
            relief of headaches, muscle stiffness and the reduction of tension.
            Indian Head Massage can be invigorating whilst being a truly
            relaxing experience.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-6">
          <b>Chiropractic Clinic</b>
          <p>
            Although chiropractic services are very effective in relieving back
            and neck pain, the role of a chiropractor extends far beyond “fixing
            a bad back”. By working primarily with the spinal column, the
            Chiropractors’ ultimate goal is to restore, maintain and optimise
            spinal alignment and nervous systems function. Colin’s clinic is
            open Wednesdays.
          </p>
        </div>
        <div className="col-12 col-md-6">
          <b>Swedish Massage</b>
          <p>
            Swedish Massage, or Classic Massage as it is also known, is a gentle
            treatment focused on loosening tight muscles and stretching
            connective tissue. This whole body therapeutic treatment works to
            release tension and bring balance to the body. Swedish Massage has a
            wide range of benefits from improved sleep and circulation to a
            reduction in back, neck and shoulder pain to name a few.
          </p>
        </div>
        <div className="col-6 col-md-6">
          <b>Acupuncture</b>
          <p>
            Acupuncture treats the person as a whole as opposed to looking at a
            specific symptom. Chinese Medicine teaches us that symptoms and pain
            are the body’s way of indicating there is an underlying imbalance
            that is causing the body to deviate from its natural state of health
            and well-being.
          </p>
          <p>
            Acupuncture is evidence based and has been shown to be an effective
            way to treat a wide number of conditions including chronic pain,
            headaches/migraines, sleep disorders, stress and anxiety,
            depression, digestive disorders, menopause, endometriosis, menstrual
            disorders and/or irregularity and fertility.
          </p>
        </div>
      </div>
      <br />
      <div className="row justify-content-center">
        <div className="col-12 text-center">
          <p>
            Download our{" "}
            <a href={brochure} target="_blank" rel="noreferrer noopener">
              Balcony Brochure
            </a>{" "}
            for more information
          </p>
        </div>
      </div>
    </div>
  );
};

export default TherapiesPage;
