import React from "react";

import background from "../images/personal_training.jpg";
import trainerBio from "../images/trainer_bio.jpeg";

const PersonalTrainingPage = props => {
  const loc = props.match.params.loc;
  return (
    <div id="personaltrainingpage">
      <div className="row justify-content-center">
        <div className="col-lg-8 text-center">
          <img className="img-fluid mb-4" src={background} alt="header" />
            <div className="text-left">
              <h4>Personal Training</h4>
              <p>
                Choosing Personal Training will ensure you maximise your time
                exercising and start seeing results quicker than ever before. No
                matter what your goals are everyone can benefit from the experience
                and knowledge a qualified Personal Trainer can bring to the mix. At
                The Fountain Spa each Personal Trainer brings the qualities required
                for you to see the results you desire. To lose weight, tone up,
                improve performance and increase energy levels - Personal Training
                can be a vital ingredient. For results in the Gym you need to know
                the correct techniques to use – this is where a friendly, qualified
                and motivated Personal Trainer can make the difference.{" "}
              </p>
              <p>
                To find out more about Personal Training and the tremendous rates
                available please feel free to speak with a Member of staff.
              </p>
          </div>
        </div>
      </div>
      {loc === 'glenrothes' && <div className='row justify-content-center'>
        <div className="col-12 text-center">
              <p>
                <a
                  href={trainerBio}
                  target='_blank'
                  rel='noreferrer noopener'
									className='btn btn-alt bg-dark mb-3'
								>
									Meet our Trainer
								</a>
              </p>
        </div>
      </div>}
    </div>
  );
};

export default PersonalTrainingPage;
