import React from "react";
import { Link } from "react-router-dom";

import closeMenuIcon from "../images/exit.png";
import eWelcomePack from "../pdfs/edinburgh_welcome_pack.pdf";
import gWelcomePack from "../pdfs/glenrothes_welcome_pack.pdf";
import eTimetable from "../pdfs/edinburgh_timetable.pdf";
import gTimetable from "../pdfs/glenrothes_timetable.pdf";

const CollapsedNavBar = (props) => {
	return (
		<div id='collapsed-menu'>
			<img
				id='close-menu-icon'
				src={closeMenuIcon}
				height='35px'
				alt='close menu icon'
				onClick={() => props.history.goBack()}
			/>
			<ul>
				<li className='nav-header'>Glenrothes Spa</li>
				<li>
					<Link to='/glenrothes/membership'>Membership</Link>
				</li>
				<li>
					<Link to='/glenrothes/fitness'>Fitness</Link>
				</li>
				<li>
					<Link to='/glenrothes/wetside'>Wetside Facilities</Link>
				</li>
				<li>
					<Link to='/glenrothes/classes'>Classes</Link>
				</li>
				<li>
					<Link to='/glenrothes/personal_training'>Personal Training</Link>
				</li>
				<li>
					<a href={gTimetable} target='_blank' rel='noreferrer noopener'>
						Class Timetable
					</a>
				</li>
				<li>
					<a href={gWelcomePack} target='_blank' rel='noreferrer noopener'>
						Information Pack
					</a>
				</li>
				<li>
					<Link to='/glenrothes/faq'>FAQ</Link>
				</li>
				<li>
					<Link to='/glenrothes/contact'>Contact</Link>
				</li>
				{/* <li className='nav-header'>Edinburgh Spa</li>
				<li>
					<Link to='/edinburgh/membership'>Membership</Link>
				</li>
				<li>
					<Link to='/edinburgh/fitness'>Fitness</Link>
				</li>
				<li>
					<Link to='/edinburgh/wetside'>Wetside Facilities</Link>
				</li>
				<li>
					<Link to='/edinburgh/classes'>Classes</Link>
				</li>
				<li>
					<a href={eTimetable} target='_blank' rel='noreferrer noopener'>
						Class Timetable
					</a>
				</li>
				<li>
					<Link to='/edinburgh/health_and_beauty'>Health & Beauty</Link>
				</li>
				<li>
					<Link to='/edinburgh/therapies'>Complementary Therapies</Link>
				</li>
				<li>
					<Link to='/edinburgh/personal_training'>Personal Training</Link>
				</li>
				<li>
					<a href={eWelcomePack} target='_blank' rel='noreferrer noopener'>
						Information Pack
					</a>
				</li>
				<li>
					<Link to='/edinburgh/faq' replace>
						FAQ
					</Link>
				</li>
				<li>
					<Link to='/edinburgh/contact' replace>
						Contact
					</Link>
				</li> */}
			</ul>
		</div>
	);
};

export default CollapsedNavBar;
