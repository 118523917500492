import React from "react";

import logo from "../images/membership_page.jpg";
import eWelcomeIcon from "../images/welcome_pack_icon.jpg";

const MembershipPage = (props) => {
  const loc = props.match.params.loc;
  return (
    <div id="membershippage">
      <div className="row justify-content-center">
        <div className="col-lg-8 ">
          <img className="img-fluid mb-4" src={logo} alt="header" />
          <h4>Gym Membership</h4>
          <ol>
            <li>
              Start by downloading our{" "}
              <a
                href={
                  loc === "edinburgh"
                    ? require("../pdfs/edinburgh_welcome_pack.pdf")
                    : require("../pdfs/glenrothes_welcome_pack.pdf")
                }
                target="_blank"
                rel="noreferrer noopener"
              >
                information pack
              </a>
            </li>
            <li>
              Call the site to arrange an appointment for a tour and Membership
              overview
            </li>
          </ol>
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-12">
          <h5>Rates</h5>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <b>Full Membership:</b>
          <ul>
            <li>
              Full Single Membership -{" "}
              {loc === "edinburgh" ? "£59.55" : "£55.65"}
            </li>
            <li>
              Full Joint Membership -{" "}
              {loc === "edinburgh" ? "£107.10" : "£100.20"}
            </li>
          </ul>
          <b>Off Peak Membership:</b>
          <ul>
            <li>
              Off Peak Single Membership -{" "}
              {loc === "edinburgh" ? "£50.65" : "£47.25"}
            </li>
            <li>
              Off Peak Joint Membership -{" "}
              {loc === "edinburgh" ? "£91.10" : "£85.00"}
            </li>
          </ul>
          <b>Child Membership (Under 16's):</b>
          <ul>
            <li>Child {loc === "edinburgh" && "Single"} Membership - £18.00</li>
            <li>Child Joint Membership - £20.00</li>
          </ul>
        </div>
        <div className="col-2 text-center">
          <a
            href={
              loc === "edinburgh"
                ? require("../pdfs/edinburgh_welcome_pack.pdf")
                : require("../pdfs/glenrothes_welcome_pack.pdf")
            }
            target="_blank"
            rel="noreferrer noopener"
          >
            <img
              className="form-img"
              alt="Information Pack Icon"
              src={eWelcomeIcon}
              style={{ height: "115px" }}
            />
          </a>
          <a
            href={
              loc === "edinburgh"
                ? require("../pdfs/edinburgh_welcome_pack.pdf")
                : require("../pdfs/glenrothes_welcome_pack.pdf")
            }
            target="_blank"
            rel="noreferrer noopener"
          >
            <b>Information Pack</b>
          </a>
        </div>
      </div>
    </div>
  );
};

export default MembershipPage;
